<template>
    <div >
        <div class="section-subtitle">
            <i class="icon icon-grid"></i>
            <span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                :isGkkpPaid="true"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadRepPaid="downloadRepPaid"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>

        <div class="filter-container">
            <div class="left-content">
                <p v-if="!currTabCategoryMode" class="title">{{ getCommonText('decoding') }} {{ decodeTabAdditionalTitle }}. {{ currTabCategoryName }}</p>
            </div>
            <div class="right-content">
                <c-budget-forms-data-transfer
                    v-if="currTabCategoryMode && !isGkkpMode && !(progress < 100) && variantAttribute"
                    :header="header"
                    :lng="lng"
                    :getDataTransferText="getDataTransferText"
                    :getErrText="getErrText"
                    :makeToast="makeToast"
                    @reloadTable="loadCategoryData"
                />
                <div class="filter-actions filter-actions-flex">
                    <!-- TODO сделать копирование данных -->
                    <template v-if="currTabCategoryMode">
                        <c-budget-forms-copy-data
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="isPaidTab ?  budgetFormPaid : budgetForm"
                            :header="header"
                            :isLoad="isLoad"
                            :isPaidTab="isPaidTab"
                            @keyPress="keyPress"
                        />
                        <b-button variant="primary" @click="onAddCategoryClicked" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    </template>
                    <template v-else>
                        <b-button variant="light" @click="onGoBackBtnClicked">
                           <i class="icon icon-keyboard icon-rotate-180"></i> {{ getCommonText('back_to_list') }}
                        </b-button>
                        <b-button :style="{ 'minWidth': '110px' }" v-if="variantAttribute" variant="primary" @click="onAddGoodBtnClicked">
                            <i class="icon icon-plus-circle"></i>{{ getCommonText('add') }}
                        </b-button>
                        <b-button
                            :style="{ 'minWidth': '110px' }" 
                            :disabled="!dataChanged || goodsAreSaving"
                            variant="success"
                            @click="onSaveGoodBtnClicked">
                            {{ getCommonText('save') }}
                        </b-button>
                    </template>
                </div>
            </div>
        </div>
        
        <b-tabs 
            active-nav-item-class="budget-form_tabs_active_tab"
            nav-class="budget-form_tabs" 
            v-model="activeTabIndex"
        >
            <b-tab 
                :title="getCommonText('budget_expense')"
                @click="updateRoute(0)" 
            >
                <!-- Первая вклапдка -->
                <form-03-149-tab1  
                    ref="formTab"
                    v-show="categoryMode"
                    :budgetForm="budgetForm"
                    :deletingCat="deletingCat"
                    :dict="dict"
                    :getCommonText="getCommonText"
                    :getUnitsText="getUnitsText"
                    :isLoad="isLoad"
                    :selectAll="selectAll"
                    :setFormText="setFormText"
                    :total="total"
                    :variantAttribute="variantAttribute"
                    @addItem="onAddCategoryClicked"
                    @changeValue="changeValueBudg"
                    @deleteItem="deleteItem"
                    @onAllFilesClick="onAllFilesClick"
                    @setCurrCatName="setCurrCatName"
                    @setSelectAll="setSelectAll"
                ></form-03-149-tab1>

                <!-- Первая вклапдка -->
                <template v-if="!categoryMode">
                    <b-overlay :show="templateTableOverlay" rounded="sm">
                        <form-03149-template1
                            ref="formTemplate"
                            :formCode="form.code"
                            :goodsData="goodsData"
                            :initialGoodsData="initialGoodsData"
                            :ensTruDict="ensTruDict"
                            :variantAttribute="variantAttribute"
                            :currCategory="currCategory"
                            :lang="lang"
                            :ifAllFieldsFilled="ifAllFieldsFilled"
                            :deleteGood="deleteGood"
                            :getUnitName="getUnitName"
                            :ensTruNamePrt="ensTruNamePrt"
                            :ensTruName="ensTruName"
                            :currUnits="currUnits"
                            :descIsLoading="descIsLoading"
                            :parNameLoading="parNameLoading"
                            :allFiles="allFiles"
                            @doublesValidationDecodes="doublesValidationDecodes"
                            @setTotals="setTotals"
                            @updateDatas="updateDatas"
                            @save="save"
                            @onDataChanged="onDataChanged"
                            @searchName="searchName"
                            @setIsAllDelete="setIsAllDelete"
                            @deleteSeveralGoods="deleteSeveralGoods"
                            :triggDownloadFile="triggDownloadFile"
                            :showFileUploadModal="showFileUploadModal"
                            :getEnstruByName="getEnstruByName"
                            :loadCurUnits="loadCurUnits"
                            :goodsAreSaving="goodsAreSaving"
                            :isDoubleDecodeExist="isDoubleDecodeExist"
                        ></form-03149-template1>
                        <div class="table-add" v-if="variantAttribute">
                            <span @click="$refs.formTemplate.addItem()"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
                        </div>
                    </b-overlay>
                </template>

                <!-- Первая вклапдка -->
                <files-updown ref="filesUpdown"
                    v-show="categoryMode"
                    :header="header"
                    :variant-attribute="variantAttribute"
                    :load="load"
                    :showDeleteLinks="variantAttribute"
                    :showDeleteAll="true"
                    @getFiles="getFiles"
                    @fillFilesList="fillFilesList"
                    @restetFilesList="restetFilesList"
                    @delelteAttachedFile="delelteAttachedFile"
                    @onFilePreview="onFilePreview"
                ></files-updown>

                <modal-all-files-management
                    ref="modalAllFilesManagement"
                    :allFiles="allFiles"
                    @triggDownloadFile="triggDownloadFile"
                    @triggDownloadAllFiles="triggDownloadAllFiles"
                >
                </modal-all-files-management>
            </b-tab>

            <b-tab 
                :title="getCommonText('paid_services')" 
                @click="updateRoute(1)"
            >
                <!-- Вторая вклапдка -->
                <form-03-149-tab1  
                    ref="formTabPaid"
                    v-show="categoryModePaid"
                    :budgetForm="budgetFormPaid"
                    :deletingCat="deletingCat"
                    :dict="dictPaid"
                    :getCommonText="getCommonText"
                    :getUnitsText="getUnitsText"
                    :isLoad="isLoad"
                    :selectAll="selectAllPaid"
                    :setFormText="setFormText"
                    :total="totalPaid"
                    :variantAttribute="variantAttribute"
                    @addItem="onAddCategoryClicked"
                    @changeValue="changeValuePaid"
                    @deleteItem="deleteItemPaid"
                    @onAllFilesClick="onAllFilesClickPaid"
                    @setCurrCatName="setCurrCatNamePaid"
                    @setSelectAll="setSelectAll"
                ></form-03-149-tab1>

                <!-- Вторая вклапдка -->
                <template v-if="!categoryModePaid">
                    <b-overlay :show="templateTableOverlay" rounded="sm">
                        <form-03149-template1
                            ref="formTemplatePaid"
                            :formCode="form.code"
                            :goodsData="goodsDataPaid"
                            :initialGoodsData="initialGoodsDataPaid"
                            :ensTruDict="ensTruDict"
                            :variantAttribute="variantAttribute"
                            :currCategory="currCategoryPaid"
                            :lang="lang"
                            :ifAllFieldsFilled="ifAllFieldsFilledPaid"
                            :deleteGood="deleteGoodPaid"
                            :getUnitName="getUnitName"
                            :ensTruNamePrt="ensTruNamePrt"
                            :ensTruName="ensTruName"
                            :currUnits="currUnits"
                            :descIsLoading="descIsLoading"
                            :parNameLoading="parNameLoading"
                            :allFiles="allFilesPaid"
                            @setTotals="setTotals"
                            @updateDatas="updateDatasPaid"
                            @save="savePaid"
                            @onDataChanged="onDataChanged"
                            @searchName="searchName"
                            @setIsAllDelete="setIsAllDeletePaid"
                            @deleteSeveralGoods="deleteSeveralGoodsPaid"
                            @doublesValidationDecodes="doublesValidationDecodes"
                            :triggDownloadFile="triggDownloadFilePaid"
                            :showFileUploadModal="showFileUploadModalPaid"
                            :getEnstruByName="getEnstruByName"
                            :loadCurUnits="loadCurUnits"
                            :goodsAreSaving="goodsAreSaving"
                            :isDoubleDecodeExist="isDoubleDecodeExist"
                        ></form-03149-template1>
                        <div class="table-add" v-if="variantAttribute">
                            <span @click="$refs.formTemplatePaid.addItem()"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
                        </div>
                    </b-overlay>
                </template>

                <!-- Вторая вклапдка -->
                <files-updown ref="filesUpdownPaid"
                    v-show="categoryModePaid"
                    :header="header"
                    :variant-attribute="variantAttribute"
                    :load="load"
                    :showDeleteLinks="variantAttribute"
                    :showDeleteAll="true"
                    :isPaidTab="true"
                    @getFiles="getFilesPaid"
                    @fillFilesList="fillFilesListPaid"
                    @restetFilesList="restetFilesListPaid"
                    @delelteAttachedFile="delelteAttachedFilePaid"
                ></files-updown>
            </b-tab>

            <modal-all-files-management
                ref="modalAllFilesManagementPaid"
                :allFiles="allFilesPaid"
                @triggDownloadFile="triggDownloadFilePaid"
                @triggDownloadAllFiles="triggDownloadAllFilesPaid"
            >
            </modal-all-files-management>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import Form03149Template1 from "./components/budget-forms-template1.vue";
import FormsHandlerMixinPaid from "./mixins/forms-handler-mixin-paid";
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import Form03149Tab1 from './Form03_149_tab1.vue';
import CBudgetFormsDataTransfer from './components/budget-forms-data-transfer.vue';

export default {
    name: 'Form03_149',
    components: { 
                    FilesUpdown,
                    BudgetFormsList,
                    FormsDownloadReprt,
                    Form03149Template1,
                    ModalAllFilesManagement,
                    CBudgetFormsCopyData,
                    Form03149Tab1,
                    CBudgetFormsDataTransfer
                },
    mixins: [FormsHandlerMixinPaid],
    data() {
        return {
            form: {
                code: '03-149',
                name_ru: 'Расчет расходов по приобретению товаров, необходимых для обслуживания и содержания основных средств, строительных материалов, используемых на ремонт основных средств, запасных частей для оборудования, транспортных средств и других запасов, непосредственно связанных с содержанием, обслуживанием и ремонтом',
                name_kk: 'Негізгі құралдарды ұстау және қызмет көрсету үшін қажет тауарларды, негізгі құралдарды жөндеуге пайдаланылатын құрылыс материалдарын, жабдықтар, көлік құралдары үшін қосалқы бөлшектер және ұстаумен, қызмет көрсетумен және жөндеумен тікелей байланысты басқа қорларды сатып алу бойынша шығыстардын есебіу'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            budgetFormPaid: [],
            header: null,
            files: null,
            filesPaid: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            isReportUploading: false,
            modalMaxWidth: '1300px',
        };
    },
    created() {
        this.$emit('setForm', this.form);
    },
    mounted() {
        this.progress = 15;
    },

    watch: {
        lang() {
            this.dictSortingByName(this.dict);
            this.dictSortingByName(this.dictPaid);
        },
        openDisabled() {
            this.$emit('setOpenDisabled', this.openDisabled);
        },
        currTabCategoryMode() {
            this.$emit('setCategoryMode', this.currTabCategoryMode);
        },
        progress() {
            this.$emit('setProgress', this.progress);
        },
        selectAllPaid() {
            this.$refs.formTabPaid.setSelectAll(this.selectAllPaid);
        },

        selectAll() {
            this.$refs.formTab.setSelectAll(this.selectAll);
        },
        currTabName() {
            this.$emit('setCurrTabName', this.currTabName);
        }
    },

    methods: {
        addItem(budgetForm) {
            if (this.isNotSavedCatExist) return;
            const item = { id: -1 };
            this.itemUpdate(item);
            budgetForm.push(item);
        },

        changeValueBudg(item, value, data) {
            this.changeValue(item, value, data);
            this.disableDictItms();
        },

        changeValuePaid(item, value, data) {
            this.changeValue(item, value, data);
            this.disableDictItmsPaid();
        },

        changeValue(item, value, data) {
            this.deifineUnit(item);
            this.$set(item, 'categoryCode', value.code);
        },

        disableDictItms() {
            this.disableDictItmsHandler(this.dict, this.budgetForm);
        },
        disableDictItmsPaid() {
            this.disableDictItmsHandler(this.dictPaid, this.budgetFormPaid);
        },
        disableDictItmsHandler(dict, budgetForm) {
            dict.forEach(dictItm => {
                if (budgetForm.findIndex(frm => frm.categoryCode === dictItm.code) !== -1) {
                    this.$set(dictItm, '$isDisabled', true);
                } else {
                    this.$set(dictItm, '$isDisabled', false);
                };
            });
        },

        getItem(code, list) {
            for (const item of list) {
                if (item.code === code) {
                    return item;
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка


        itemUpdate(item) {
            this.$set(item, 'categoryCode', null);
            this.$set(item, 'good_type', null);
            this.$set(item, 'amount', 0);
            this.$set(item, 'price', 0);
            this.$set(item, 'unit', null);
            this.$set(item, 'total', 0);
            this.$set(item, 'newCat', true);
            this.$set(item, 'itemToDelete', false);
        },

        getFiles(data) {
            this.files = data;
        },
        getFilesPaid(data) {
            this.filesPaid = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        async loadCategoryData() {
            this.load = true;
            this.budgetForm.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat/' + JSON.stringify(this.header));
                    if (response.status === 200) {
                        const values = await response.json();
                        this.fillBudgetForm(values, this.budgetForm);
                        this.disableDictItms();
                    }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadCategoryData()`, error.toString());
            }
            this.load = false;
        },

        async loadCategoryDataPaid() {
            this.load = true;
            this.budgetFormPaid.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat-paid/' + JSON.stringify(this.header));
                    if (response.status === 200) {
                        const values = await response.json();
                        this.fillBudgetForm(values, this.budgetFormPaid);
                        this.disableDictItmsPaid();
                    }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadCategoryData()`, error.toString());
            }
            this.load = false;
        },

        fillBudgetForm(values, budgetForm) {
            values.forEach((val, i) => {
                const item = {
                    id: i,
                    categoryCode: val.good_type,
                    good_type: this.getItem(val.good_type, this.dictPaid),
                    amount: val.sum_amount,
                    price: this.safeDecimal(val.avg_price).toDecimalPlaces(2).toNumber(),
                    total: this.safeDecimal(val.sum_total).div(1000).toDecimalPlaces(3).toNumber(),
                    files: this.getUnicFilesArr(val),
                    newCat: false,
                    itemToDelete: false,
                };
                this.deifineUnit(item);
                budgetForm.push(item);
            });
            budgetForm.sort((a, b) => a.id - b.id);
        },

        fillDataForSave(item, row) {
            // при необходимости заменить в соответствии с формой
            this.$set(item, 'good_type', this.currCategory.code);
            this.$set(item, 'amount', parseFloat(row.amount));
            this.$set(item, 'price', parseFloat(row.price));
        }, // заполнение полей при подготовке к сохранению товаров

        fillDataForSavePaid(item, row) {
            // при необходимости заменить в соответствии с формой
            this.$set(item, 'good_type', this.currCategoryPaid.code);
            this.$set(item, 'amount', parseFloat(row.amount));
            this.$set(item, 'price', parseFloat(row.price));
        }, // заполнение полей при подготовке к сохранению товаров

        deifineUnit(item) {
            Object.defineProperty(item, 'unit', {
                get: () => {
                    if (this.lng === 'kk') return item.good_type.unit_kk;
                    return item.good_type.unit;
                }
            });
        },

        noAbc: function (event) {
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит только цифры

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке
    },

    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        },
        totalPaid() {
            let sum = 0;
            for (const row of this.budgetFormPaid) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        },
    }
};

</script>

<style scoped>
#goods-modal-header-i {
    cursor: pointer;
}
.filter-actions-flex {
    display: flex;
}

.budget-form_tabs_active_tab {
    background-color: '#F7F9FC';
}
.budget-form_tabs {
    display: none;
}
</style>